.trivia {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  @include media-breakpoint-down(md) {
    padding-top: 80px;
    justify-content: flex-start;
  }

  .quiz-label {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 15px;

    p {
      color: $red;
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      margin-top: 60px;

      @include media-breakpoint-down(md) {
        margin-top: 35px;
      }
    }

    img {
      height: 40px;
    }
  }

  img {
    max-width: 400px;

    @include media-breakpoint-down(md) {
      width: 350px;
    }
  }

  .trivia-intro {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .trivia-text {
    @include media-breakpoint-down(md) {
      text-align: justify;
    }
  }
}
