.survey {
  padding-top: 200px;
  position: relative;

  @include media-breakpoint-down(md) {
    padding-top: 150px;
  }

  .survey-label {
    position: absolute;
    top: 15px;
    width: 100%;
    text-align: center;

    p {
      color: $white;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      margin-top: 80px;

      @include media-breakpoint-down(md) {
        margin-top: 40px;
      }
    }

    img {
      height: 42px;
    }
  }
  .question-text {
    text-align: center;
    color: $white;
    font-size: 32px;
    font-weight: 700;
    line-height: 41px;

    @include media-breakpoint-down(md) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  .answer-section {
    @include media-breakpoint-down(md) {
      padding: 0 5px 25px 5px;
    }
  }

  .btn-survey {
    background-color: $white;
    box-shadow: none;
    margin: 5px;
    position: relative;
    border: 3px solid transparent;
    border-radius: 40px;
    color: $grey;
    font-size: 18px;
    font-weight: 500;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 340px;
    height: -webkit-fill-available;
    height: -moz-fill-available;
    line-height: 20px;
    padding: 16px 20px;
    transition: background-color 0.5s, border 0.5s, color 0.5s;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 18px;
    }

    &:active,
    &:focus {
      background-color: #5b7f95;
      border: 3px solid $white;
      color: $white;
      filter: brightness(95%);
    }
    &:hover {
      opacity: 0.9 !important;
    }

    // Disable hover on mobile devices
    @media (hover: none) {
      &:hover {
        opacity: 1 !important;
      }
    }
  }
}
