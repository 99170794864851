.quiz {
  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  p {
    color: $grey;
  }

  .btn-answer {
    background-color: $white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    margin: 5px;
    position: relative;
    transition: none;
    border: none;
    border-radius: 40px;
    color: $grey;
    font-size: 18px;
    font-weight: 500;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 340px;
    height: -webkit-fill-available;
    height: -moz-fill-available;
    line-height: 20px;
    padding: 16px 20px;

    &:active,
    &:focus {
      border: none;
    }

    &:hover {
      background-color: rgba(177, 179, 179, 0.2);
    }

    @include media-breakpoint-down(md) {
      font-size: 16px;
      margin: 5px auto;
      width: 100%;
      height: -webkit-fill-available;
      height: -moz-fill-available;
    }

    .animation {
      align-items: center;
      flex-direction: row-reverse;
      border-radius: 40px;
    }

    &:focus {
      .animation__active {
        display: flex;
        pointer-events: none;
        background-color: rgba(177, 179, 179, 0.5);
        border: none;
      }
    }

    &:disabled {
      opacity: 1;
    }

    &.wrong {
      pointer-events: none;
      background-color: rgba(218, 41, 28, 0.6);
      border: none;
      color: $white;

      .animation__wrong {
        display: flex;
        background-color: rgba(178, 44, 55, 0.5);

        .icon {
          color: $white;
          font-size: 35px;
          padding-right: 10px;
        }
      }
    }

    &.correct {
      pointer-events: none;
      background-color: rgba(91, 127, 149, 0.8);
      border: none;
      color: $white;

      .animation__correct {
        display: flex;
        background-color: rgba(122, 153, 172, 0.5);

        .icon {
          color: $white;
          font-size: 35px;
          padding-right: 10px;
        }
      }
    }
  }

  .disable {
    .btn {
      pointer-events: none;
      opacity: 1;
    }
  }

  .answer-section {
    .animation {
      display: none;
      position: absolute;
      transform: none;
      border-radius: 40px;
      color: $white;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}
