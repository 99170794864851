.question {
  padding-top: 150px;

  @include media-breakpoint-down(md) {
    padding-top: 100px;
  }

  .lead {
    padding: 0 100px;

    @include media-breakpoint-down(md) {
      padding: 0 10px;
    }
  }
  .question-image {
    img {
      height: 240px;
    }
  }
  .answer-section {
    max-width: 700px;
    margin: 30px auto;

    @include media-breakpoint-down(md) {
      width: 80%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 0 5px;
    }
  }
}
