// Colors

$white: #fff;
$black: #000;
$grey: #3b3938;
$grey-light: #97999b;
$red: #da291c;
$blue: #7a99ac;
$blue-dark: #5b7f95;

$primary: $white;
$secondary: $black;
$headings-color: $white;

$bg-gradient: linear-gradient(136deg, #52879b -34.29%, #bcdcdf 81.28%);

$font-family-sans-serif: 'CitroenType', sans-serif;
$headings-font-family: 'CitroenType', sans-serif;

$utilities: (
  'opacity': (
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      90: 0.9,
      100: 1,
    ),
  ),
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: (
    $spacer * 0.75,
  ),
  4: $spacer,
  5: (
    $spacer * 1.25,
  ),
  6: (
    $spacer * 1.5,
  ),
  7: (
    $spacer * 1.75,
  ),
  8: (
    $spacer * 2,
  ),
  9: (
    $spacer * 2.25,
  ),
  10: (
    $spacer * 2.5,
  ),
  11: (
    $spacer * 3,
  ),
  12: (
    $spacer * 3.5,
  ),
  13: (
    $spacer * 4,
  ),
  14: (
    $spacer * 4.5,
  ),
  15: (
    $spacer * 5,
  ),
  16: (
    $spacer * 6,
  ),
  17: (
    $spacer * 7,
  ),
  18: (
    $spacer * 8,
  ),
  19: (
    $spacer * 9,
  ),
  20: (
    $spacer * 10,
  ),
  21: (
    $spacer * 12.5,
  ),
  22: (
    $spacer * 15,
  ),
  23: (
    $spacer * 17.5,
  ),
  24: (
    $spacer * 20,
  ),
  25: (
    $spacer * 22.5,
  ),
);

$theme-colors: (
  'primary': $white,
  'secondary': $black,
  'white': $white,
  'grey': $grey,
  'grey-light': $grey-light,
  'red': $red,
  'blue': $blue,
  'blue-dark': $blue-dark,
);

// Button
$btn-focus-box-shadow: 0;
$btn-color: $white;
$btn-hover-color: $white;
$btn-border-radius: 40px;
$btn-font-family: $headings-font-family;
$btn-font-size: 22px;
$btn-font-weight: 700;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

// Form
$input-bg: $white;
$input-btn-font-size-sm: 0.8rem;
$input-border-color: $grey-light;
$input-border-width: 2px;
$input-focus-border-color: $blue;
$input-color: $grey;
$input-focus-box-shadow: none;
$input-padding-x: 1.3rem;
$input-padding-y: 0.4rem;

$form-check-input-bg: $white;
$form-check-input-width: 1.5em;
$form-check-radio-border-radius: 5px;
$form-check-input-border-radius: 5px;
$form-check-input-border: 1.5px solid $blue;
$form-check-input-focus-border: 1.5px solid $blue;
$form-check-input-checked-color: $white;
$form-check-input-checked-bg-color: $blue;
$form-check-input-checked-border-color: $white;
$form-check-input-focus-box-shadow: 'none';

// Cards
$card-border-radius: 15px;

$enable-negative-margins: true;

@import '~bootstrap/scss/bootstrap';
