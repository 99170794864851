.quiz-status {
  .current-question {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    color: $blue-dark;
  }
}

.quiz-label {
  width: 100%;
  top: 40px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  color: $red;
}
