.conditions {
  .logo {
    position: absolute;
    height: 40px;
    top: 10px;
    right: 10px;
  }

  .quiz-label {
    width: 100%;
    top: 40px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    color: $red;
  }

  .modal-text {
    padding: 130px 20px 10px 20px;
    max-width: 600px;
  }
}
