.optin {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 36px;
    line-height: 40px;

    @include media-breakpoint-down(md) {
      font-size: 26px;
      line-height: 32px;
    }
  }

  .optin-content {
    max-width: 600px;
    margin: 10px auto;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .form-check {
    margin: 1rem auto;
  }

  .form-check-input {
    margin-top: 5px;

    @include media-breakpoint-down(md) {
      width: 1.2em;
      height: 1.2em;
    }
  }

  .form-control.is-invalid,
  .form-check-input.is-invalid {
    border-color: #b22c37;
  }

  .form-check-label {
    &.is-invalid {
      color: #b22c37;
    }
  }

  .optin-form {
    padding: 10px 50px;

    @include media-breakpoint-down(md) {
      padding: 10px 20px;
    }
  }

  .optin-btn-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @include media-breakpoint-down(md) {
      margin-top: 0px;
    }
  }

  .link {
    color: $blue;
  }

  .guest-result {
    border: none;
    color: $blue-dark;

    &:active {
      box-shadow: none;
      outline: none;
      color: $blue;
    }

    &:hover {
      box-shadow: none;
      outline: none;
      color: $blue;
    }

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }
}
