.result-bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  @include media-breakpoint-down(md) {
    padding: 0;
    margin-top: 10px;
  }

  .score-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;
  }

  .point {
    font-size: 28px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 5px;

    @include media-breakpoint-down(md) {
      font-size: 20px;
      line-height: 22px;
    }
  }

  .result-points {
    color: $grey;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 5px;

    @include media-breakpoint-down(md) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
