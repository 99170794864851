.results {
  position: relative;

  @include media-breakpoint-down(md) {
    padding-top: 100px;
    padding-bottom: 40px;
  }

  .quiz-label {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 15px;

    p {
      color: $red;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      margin-top: 50px;

      @include media-breakpoint-down(md) {
        margin-top: 20px;
      }
    }

    img {
      height: 40px;

      @include media-breakpoint-down(md) {
        height: 30px;
      }
    }
  }

  .segment-image {
    img {
      width: 100%;
    }
  }

  .segment-description {
    @include media-breakpoint-down(md) {
      font-size: 14px;
      text-align: justify;
      margin-top: 12px;
      padding: 0 5px;
    }
  }

  .resultbar-wrap {
    @include media-breakpoint-down(md) {
      padding: 3px;
    }
  }

  .scorebar-descr {
    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }

  .share-wrapper {
    display: flex;
    align-items: center;
    border: none;
    justify-content: flex-start;
    height: 38px;
    background-color: transparent;
    padding: 3px 0px;

    @include media-breakpoint-down(md) {
      height: 30px;
      padding: 3px 0;
    }

    .btn {
      border: none;
    }

    svg {
      height: 25px;
      @include media-breakpoint-down(md) {
        height: 20px;
      }
    }
  }

  .share-button {
    margin: 1px 2px;
    display: inline-flex;
    @include media-breakpoint-down(md) {
      padding: 6px 8px;
    }

    &.copied {
      pointer-events: none;
    }
  }

  .share-button svg path {
    fill: $red;
    transition: opacity 0.5s ease;
  }

  .share-button:hover svg path {
    fill: $blue; /* Fill color on hover */
  }

  .share-button:active svg path {
    fill: $blue; /* Fill color on click */
  }

  .share-button.copied svg path {
    fill: #09aa59;
  }

  .result-buttons {
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .btn {
      width: 250px;
    }

    .btn-main {
      margin: 0 5px;
      @include media-breakpoint-down(md) {
        margin: 2px auto;
      }
    }

    .btn-again {
      border: 2px solid $red;
      color: $red;
      border-radius: 40px;
      padding: 12px 32px;
      font-weight: 700;
      line-height: normal;
      font-size: 22px;
      margin: 0 5px;

      &:hover,
      :active {
        border: 2px solid $red;
        background-color: $red;
        color: $white;
      }

      @include media-breakpoint-down(md) {
        height: auto;
        margin: 5px auto;
        line-height: 22px;
        font-size: 20px;
      }
    }
  }
}
