.prizes {
  .logo {
    position: absolute;
    height: 40px;
    top: 10px;
    right: 10px;
  }

  .modal-text {
    padding: 130px 0px 10px 0px;
    max-width: 900px;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      padding: 50px 20px 10px 20px;
    }

    .quiz-label {
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      line-height: normal;
      color: $red;
    }

    .prize {
      padding: 15px 40px;

      @include media-breakpoint-down(lg) {
        padding: 15px;
      }

      img {
        max-width: 220px;
      }

      .prize-title {
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        color: $red;
      }

      .prize-description {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .btn-out {
      border: 2px solid $red;
      color: $red;
      border-radius: 40px;
      padding: 12px 52px;
      font-weight: 700;
      line-height: normal;
      font-size: 22px;
      margin: 0 5px;

      &:hover,
      :active {
        border: 2px solid $red;
        background-color: $red;
        color: $white;
      }

      @include media-breakpoint-down(md) {
        height: auto;
        margin: 5px auto;
        line-height: 22px;
        font-size: 20px;
      }
    }
  }
}
