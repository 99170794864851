// icons
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');

// --- Fonts ---

@font-face {
  font-family: 'CitroenType';
  src: url('../fonts/CitroenType-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'CitroenType';
  src: url('../fonts/CitroenType-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'CitroenType';
  src: url('../fonts/CitroenType-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CitroenType';
  src: url('../fonts/CitroenType-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

// --- Body ---

html,
body {
  background-color: $white;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.main-container {
  color: $grey;
  min-height: 100vh;
  font-family: 'CitroenType', sans-serif;
  overflow-x: hidden;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  transition: none;
  background-image: url(/assets/images/citroen-bg.png);

  &.survey-wrap {
    background: $bg-gradient;
  }

  .eb-logo {
    position: absolute;
    bottom: 3px;
    right: 3px;
    height: 30px;
  }

  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background: transparent;
  }

  .inner-wrapper {
    max-width: 900px;
    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 0 10px;
    }
  }
}

h1 {
  color: $grey;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;

  @include media-breakpoint-down(md) {
    font-size: 35px;
    line-height: 36px;
  }
}

h3 {
  color: $blue-dark;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}

p {
  color: $grey;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
}

.label {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: $red;

  @include media-breakpoint-down(md) {
    font-size: 12px;
  }
}

.btn {
  font-weight: 700;
}

.btn-main {
  border: none;
  border-radius: 40px;
  color: $white;
  background-color: $red;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 16px 32px;
  margin: 10px auto;
  width: 330px;

  @include media-breakpoint-down(md) {
    line-height: 22px;
    padding: 14px 32px;
    font-size: 20px;
  }

  &:active {
    box-shadow: none;
    outline: none;
    background-color: darken($red, 10);
    color: $white;
  }

  &:hover {
    box-shadow: none;
    outline: none;
    background-color: darken($red, 10);
    color: $white;
  }
}
