.intro {
  position: relative;

  @include media-breakpoint-down(md) {
    padding-bottom: 30px;
  }

  .eb-logo {
    position: absolute;
    bottom: 3px;
    right: 3px;
    height: 30px;
  }
  .intro-wrap {
    max-width: 1000px;
    position: relative;

    @include media-breakpoint-down(md) {
      padding-top: 50px;
    }

    .logo {
      position: absolute;
      height: 40px;
      top: 15px;

      @include media-breakpoint-down(md) {
        height: 30px;
        width: auto;
        top: 10px;
        right: 12px;
      }
    }
  }

  .intro-img {
    img {
      width: 100%;
      height: auto;
    }
  }

  .usps {
    padding: 15px 30px;
    text-align: center;

    @include media-breakpoint-down(md) {
      padding: 15px 20px;

      p {
        font-size: 12px;
      }
    }

    .icon {
      margin-bottom: 12px;
    }
  }

  .prize-link {
    text-decoration: underline;
    color: $blue-dark;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }

  .conditions-link {
    text-decoration: underline;
    color: $grey;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }
}
